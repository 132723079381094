const MenuDataBase = [
    {
        title: '数据看板',
        key: '/databoards',
        icon: 'none',
        children: [
            {
                title: '数据看板',
                key: '/databoard',
                icon: 'none',
                father: "/databoard",
                father_name: "数据看板"
            },
            {
                title: '看板设置',
                key: '/boardsetting',
                icon: 'none',
                father: "/databoard",
                father_name: "数据看板"
            },
            {
                title: '预警列表',
                key: '/earlywarning',
                icon: 'none',
                father: "/databoard",
                father_name: "数据看板"
            },
        ]
    },
    {
        title: '机构管理',
        key: '/organmanage',
        icon: 'none',
        children: [
            {
                title: '用户管理',
                key: '/usermanage',
                icon: 'none',
                father: "/organmanage",
                father_name: "机构管理"
            },
            {
                title: '机构设置',
                key: '/organsetting',
                icon: 'none',
                father: "/organmanage",
                father_name: "机构管理"
            },
        ]
    },
    {
        title: '测评管理',
        key: '/assessmanage',
        icon: 'none',
        children: [
            {
                title: '受测者管理',
                key: '/assessormanage',
                icon: 'none',
                father: "/assessmanage",
                father_name: "测评管理"
            },
            {
                title: '共享管理',
                key: '/sharemanage',
                icon: 'none',
                father: "/assessmanage",
                father_name: "测评管理"
            },
            {
                title: '报告管理',
                key: '/reportmanage',
                icon: 'none',
                father: "/assessmanage",
                father_name: "测评管理"
            },
        ]
    },
    // {
    //     title: '看板设置',
    //     key: '/boardsetting',
    //     icon: 'none',
    // },
    // {
    //     title: '预警列表',
    //     key: '/earlywarning',
    //     icon: 'none',
    // },
    {
        title: '用户中心',
        key: '/usercenter',
        icon: 'none',
        children: [
            {
                title: '个人资料',
                key: '/personaldata',
                icon: 'none',
                father: "/usercenter",
                father_name: "用户中心"
            },
            {
                title: '安全设置',
                key: '/safetysetting',
                icon: 'none',
                father: "/usercenter",
                father_name: "用户中心"
            },
            {
                title: '账户充值',
                key: '/accountrecharge',
                icon: 'none',
                father: "/usercenter",
                father_name: "用户中心"
            },
            {
                title: '充值记录',
                key: '/rechargerecord',
                icon: 'none',
                father: "/usercenter",
                father_name: "用户中心"
            },
            {
                title: '消费记录',
                key: '/consumerecord',
                icon: 'none',
                father: "/usercenter",
                father_name: "用户中心"
            },
        ]
    },
    {
        title: '任务管理',
        key: '/mydownload',
        icon: 'none',
    },
];

/**
 * 根据key值，删除菜单子项
 * @param {*} data 
 * @param {*} key 
 */
const deleteMenuDataItem = (data, key) => {
    try {
        data.forEach((item, index) => {
            if (item.key === ('/' + key)) {
                data.splice(index, 1)
                throw new Error('End Loop')
            }
            else if (item.children && item.children.length) {
                deleteMenuDataItem(item.children, key)
            }
        })
    }
    catch (e) { }
}

/**
 * 生成菜单数据
 * @param {*} data 
 * @returns 
 */
const MenuDataFun = (data) => {

    let MenuData = JSON.parse(JSON.stringify(MenuDataBase))
    // console.log(JSON.stringify(MenuData))

    if (!data.display_kanban) {
        deleteMenuDataItem(MenuData, 'databoards')
        deleteMenuDataItem(MenuData, 'databoard')
        deleteMenuDataItem(MenuData, 'boardsetting')
        deleteMenuDataItem(MenuData, 'earlywarning')
    }


    if (!data.warning) {
        deleteMenuDataItem(MenuData, 'boardsetting')
        deleteMenuDataItem(MenuData, 'earlywarning')
    }

    // 操作员：4 管理员：8 助理：16
    if (data.role === 4 || data.role === 16) {
        deleteMenuDataItem(MenuData, 'organmanage')
    }
    else if (data.role === 8) {
        deleteMenuDataItem(MenuData, 'sharemanage')
    }

    return MenuData;
}

export default MenuDataFun;