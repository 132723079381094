import React, { Component } from 'react';
import { Button, Card, Table, Pagination, message } from 'antd';
import SearchCriteria from '../../components/SearchCriteria/SearchCriteria';
import axios from 'axios';
import baseConfig from '../../config/BaseConfig';
import moment from 'moment';

/* 充值记录 */
export default class RechargeRecord extends Component {
    state = {
        list: [],
        currentPage: 1,
        count: 1,
        tableLoading: false
    };

    // 搜索条件数值
    seacrhParam = {
        date_range: [],
        start_time: '',
        end_time: '',
    };

    // 搜索条件组件初始数据
    seacrhItems = [
        { title: '充值时间', inputType: 'date_range', seacrhType: 'date_range', span: 6 },
        { title: '', inputType: 'buttons', seacrhType: '', children: [{ title: '搜索', key: 'submit' }, { title: '导出', key: 'export' }] },
    ]

    // 列表项
    columns = [
        {
            title: '时间',
            dataIndex: 'time',
            align: 'center',
            width: 200,
        },

        {
            title: '充值金额',
            dataIndex: 'order_amount',
            align: 'center',
            width: 180,
        },
        {
            title: '订单号',
            dataIndex: 'order_id',
            align: 'center',
            width: 200,
        },
        {
            title: '充值状态',
            dataIndex: 'order_flag',
            align: 'center',
            width: 150,
            render: (order_flag) => { return Number(order_flag) === 1 ? '已支付' : '未支付' },
        },
        {
            title: '支付方式',
            dataIndex: 'pay_type',
            align: 'center',
            width: 150,
        },
    ];

    // 组件挂载
    componentDidMount() {
        this.seacrhDataFun({ page: 1 });
    }

    // 导出
    exportDataFun = () => {
        let exportUrl = baseConfig.httpUrl + '/api/pay/pay-record?';

        let tempSeacrhParam = {};
        Object.assign(tempSeacrhParam, this.seacrhParam);
        delete tempSeacrhParam.date_range;

        for (let key in tempSeacrhParam) {
            if (tempSeacrhParam[key] !== undefined) {
                exportUrl += key + '=' + tempSeacrhParam[key] + '&'
            }
        }
        exportUrl += 'export=1'
        window.open(exportUrl, '_blank')
    }

    // 获取列表数据
    seacrhDataFun = (data = null) => {

        let tempParam = {};
        Object.assign(tempParam, this.seacrhParam);
        tempParam.page = this.state.currentPage;
        delete tempParam.date_range;

        if (data) {
            tempParam.page = data.page;
        }
        if (!tempParam.start_time) {
            delete tempParam.start_time;
        }
        if (!tempParam.end_time) {
            delete tempParam.end_time;
        }

        this.setState({ tableLoading: true });
        axios.get(baseConfig.httpUrl + `/api/pay/pay-record`, { params: tempParam }).then(
            response => {
                if (response.data.res === 1) {
                    const { data, page, count } = response.data;

                    data.map((dataObj) => {
                        return dataObj.key = dataObj.id
                    });

                    this.setState({
                        list: [...data],
                        currentPage: page * 1,
                        count: count,
                        tableLoading: false
                    });
                }
                else {
                    this.setState({
                        list: [],
                        currentPage: 1,
                        count: 1,
                        tableLoading: false
                    });
                    message.error({
                        content: response.data.msg,
                        className: 'custom-class',
                    });
                }
            },
            error => { }
        );
    }

    // 跟新搜索条件数值
    updateSeacrhItems = (seacrhData) => {
        let tempObj = this.formatParams(seacrhData);

        if (tempObj.date_range && tempObj.date_range.length === 2) {
            tempObj.start_time = moment(tempObj.date_range[0]).format('YYYY-MM-DD');
            tempObj.end_time = moment(tempObj.date_range[1]).format('YYYY-MM-DD');
        }
        else {
            tempObj.start_time = '';
            tempObj.end_time = '';
        }

        Object.assign(this.seacrhParam, tempObj);
    }

    // 格式化参数
    formatParams = (seacrhParam) => {
        for (let key in seacrhParam) {
            seacrhParam[key] = seacrhParam[key] === undefined ? '' : seacrhParam[key];
        }
        return seacrhParam;
    }

    // 换页
    changePage = (pageNum, pageSize) => {
        this.seacrhDataFun({ page: pageNum });
    }


    render() {
        return (
            <Card title="" style={{ minHeight: '100%' }}>
                {/* 搜索条件 */}
                <div>
                    <SearchCriteria
                        seacrhItems={this.seacrhItems}
                        exportFun={this.exportDataFun}
                        seacrhFun={this.seacrhDataFun}
                        updateSeacrhValue={this.updateSeacrhItems}
                    />
                </div>

                {/* 列表 */}
                <Table
                    dataSource={this.state.list}
                    pagination={false}
                    columns={this.columns}
                    loading={this.state.tableLoading}
                >
                </Table>

                {/* 分页 */}
                <Pagination
                    onChange={this.changePage}
                    style={{ marginTop: 30 }}
                    defaultCurrent={1}
                    current={this.state.currentPage}
                    total={this.state.count}
                    hideOnSinglePage={true}
                    showQuickJumper={{
                        goButton: <span><span>&nbsp;&nbsp;&nbsp;&nbsp;</span> <Button type='primary'>Go</Button></span>,
                    }}
                    showTotal={(total, range) => `当前显示${range[0]}-${range[1]}条  共${total}条记录 `}
                    showSizeChanger={false}
                />
            </Card >
        )
    }
}
